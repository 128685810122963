'use client'

import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { camelCase } from 'lodash'
import { Button, Cell, Checkbox, InputText, Spacer, Text } from '@vinted/web-ui'

import useFormValidationMessage from 'hooks/useFormValidationMessage'
import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import { renderValidation } from 'components/Input'
import SeparatedList from 'components/SeparatedList'
import ContentLoader from 'components/ContentLoader'

import {
  BUSINESS_TERMS_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  IMPRESSUM_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
  BUSINESS_TERMS_AND_CONDITIONS_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { navigateToPage } from 'libs/utils/window'
import { isValueInObject } from 'libs/utils/object'
import { getAuthView, getIsRealNameRequired, getNewsletterSubscription } from 'state/auth/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { AuthView, NewsletterSubscription } from 'state/auth/constants'
import { ErrorItem, RegisterUserResponse, ResponseError } from 'types/api'
import { validateUser } from 'data/api'
import { normalizedQueryParam } from 'libs/utils/url'
import { getGoogleRedirectUrl } from 'libs/utils/google'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'

import useDataDomeCaptcha from 'hooks/useDataDomeCaptcha'
import useAbTest from 'hooks/useAbTest'

import { validateEmail, validateLogin, validatePassword, validateRealName } from './utils'
import { Field } from './constants'
import { FormData } from './types'

import PasswordField from '../PasswordField'
import useSuccessUrl from '../hooks/useSuccessUrl'
import useAuthTracking from '../hooks/useAuthTracking'

const TRANSLATION_PREFIX = 'auth.register'

const fieldToTrackingTargetMap = {
  [Field.RealName]: 'full_name',
  [Field.Login]: 'username',
  [Field.Email]: 'email',
  [Field.Password]: 'password',
  [Field.SubscribeToNewsletter]: ClickableElement.NewsletterCheckbox,
  [Field.AgreeRules]: ClickableElement.PoliciesCheckbox,
}

type Props = {
  realName?: string
  email?: string
  isPasswordRequired?: boolean
  onSubmit: (data: FormData) => Promise<ResponseError | RegisterUserResponse | null>
}

const RegisterForm = ({ realName, email, isPasswordRequired, onSubmit }: Props) => {
  const { searchParams } = useLocation()

  const isRealNameRequired = useSelector(getIsRealNameRequired)
  const newsletterSubscription = useSelector(getNewsletterSubscription)
  const authView = useSelector(getAuthView)
  const { isBusinessAuth } = useAuthModal()
  const isProTermsAndConditionsFSEnabled = useSelector(
    getIsFeatureSwitchEnabled('pro_terms_and_conditions_enabled'),
  )

  const [baseError, setBaseError] = useState('')
  const [showRealNameField, setShowRealNameField] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const state = normalizedQueryParam(searchParams.state)
  const stateUrl = getGoogleRedirectUrl(state)
  const successUrl = useSuccessUrl(stateUrl)
  const { trackInputEvent, trackClickEvent } = useAuthTracking()

  const {
    setError,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    mode: 'onTouched',
    defaultValues: {
      realName,
    },
  })
  const getErrorMessage = useFormValidationMessage(errors, `${TRANSLATION_PREFIX}.fields`)
  const translate = useTranslate(TRANSLATION_PREFIX)

  const registrationFormMandatoryAbTest = useAbTest({
    abTestName: 'mandatory_markers_registration_form_web',
    shouldTrackExpose: true,
  })

  const shortenNewsletterCheckboxCopyAbTest = useAbTest({
    abTestName: 'shorten_newsletter_checkbox_copy',
    shouldTrackExpose: true,
  })

  const newsLetterSubscriptionKey =
    shortenNewsletterCheckboxCopyAbTest?.variant === 'on'
      ? 'fields.subscribe.test.title'
      : 'fields.subscribe.title'

  const shortenTncCheckboxCopyAbTest = useAbTest({
    abTestName: 'shorten_tnc_checkbox_copy',
    shouldTrackExpose: true,
  })

  const tncCheckboxWithoutAgeRestrictionAbTest = useAbTest({
    abTestName: 'remove_age_text_tnc_checkbox_web',
    shouldTrackExpose: true,
  })

  function trackLinkClick(target: ClickableElement | undefined) {
    if (!target) return undefined

    return () => trackClickEvent({ target })
  }

  function interpolateLink(url: string, eventTarget?: ClickableElement) {
    return (part: Array<ReactNode>) => (
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={trackLinkClick(eventTarget)}>
        {part}
      </a>
    )
  }

  function getLabelMandatoryMarker(fieldName: Field) {
    let mandatoryMarker = ''
    if (
      registrationFormMandatoryAbTest &&
      registrationFormMandatoryAbTest?.variant === 'a' &&
      fieldName !== 'subscribeToNewsletter'
    ) {
      mandatoryMarker = '*'
    }

    if (registrationFormMandatoryAbTest && registrationFormMandatoryAbTest?.variant === 'b') {
      mandatoryMarker =
        fieldName === 'subscribeToNewsletter'
          ? translate('fields.optional')
          : translate('fields.required')
    }

    return mandatoryMarker.length > 1 ? ` ${mandatoryMarker}` : `${mandatoryMarker}`
  }

  const getPlaceholderLabel = (fieldName: Field, translation: string) => {
    const mandatoryMarker: string = getLabelMandatoryMarker(fieldName)

    if (!mandatoryMarker) return translation

    return `${translation}${mandatoryMarker}`
  }

  const getCheckboxLabel = (fieldName: Field, translation?: ReactNode) => {
    const mandatoryMarker = getLabelMandatoryMarker(fieldName)

    if (!mandatoryMarker) return translation

    return (
      <>
        {translation}
        {mandatoryMarker}
      </>
    )
  }

  const setErrors = useCallback(
    (newErrors: Array<ErrorItem>) => {
      let newBaseError = ''
      let hasFieldError = false

      newErrors.forEach(({ field, value }) => {
        const fieldName = camelCase(field)

        if (fieldName === Field.RealName) setShowRealNameField(true)
        if (fieldName === 'base') newBaseError ||= value
        if (!isValueInObject(fieldName, Field)) return

        hasFieldError = true
        setError(fieldName, { type: 'manual', message: value })
      })

      setBaseError(hasFieldError ? '' : newBaseError)
    },
    [setError],
  )

  async function handleFormSubmit(data: FormData) {
    const response = await onSubmit(data)

    if (!response) return
    if ('errors' in response) {
      setErrors(response.errors)

      return
    }

    navigateToPage(successUrl)
  }

  useDataDomeCaptcha(() => {
    const formData = getValues()

    if (!formData) return

    handleFormSubmit(formData)
  })

  const handleInputFocus = (target: string) => () => trackInputEvent({ target, state: 'focus' })

  const handleInputBlur = (target: string) => () => trackInputEvent({ target, state: 'unfocus' })

  const getInputEvents = (field: Field) => {
    const target = fieldToTrackingTargetMap[field]

    return {
      onFocus: handleInputFocus(target),
      onBlur: handleInputBlur(target),
    }
  }

  function handleCheckboxClick(
    field: Field.AgreeRules | Field.SubscribeToNewsletter,
    onChange: (isChecked: boolean) => void,
  ) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      trackClickEvent({
        target: fieldToTrackingTargetMap[field],
        targetDetails: event.target.checked ? 'checked' : 'unchecked',
      })

      onChange(event.target.checked)
    }
  }

  function handleSubmitButtonClick() {
    const target =
      authView === AuthView.EmailRegister
        ? ClickableElement.RegisterWithEmail
        : ClickableElement.RegisterWithSocial

    trackClickEvent({ target })
  }

  function renderBaseError() {
    if (!baseError) return null

    return (
      <div className="u-ui-padding-horizontal-large u-ui-padding-top-large u-ui-padding-bottom-small">
        <Text
          text={baseError}
          theme="warning"
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
      </div>
    )
  }

  function renderRealNameField() {
    if (!isRealNameRequired && !showRealNameField) return null
    if (realName && !showRealNameField) {
      return <input {...register(Field.RealName)} type="hidden" value={realName} />
    }

    return (
      <InputText
        {...register(Field.RealName, {
          required: true,
          validate: value => (value ? validateRealName(value) : undefined),
        })}
        uncontrolled
        placeholder={getPlaceholderLabel(Field.RealName, translate('fields.real_name.title'))}
        validation={
          renderValidation(getErrorMessage(Field.RealName)) || translate('fields.real_name.hint')
        }
        aria={{ 'aria-required': true }}
        {...getInputEvents(Field.RealName)}
      />
    )
  }

  function renderEmailField() {
    if (email) return <input {...register(Field.Email)} type="hidden" value={email} />

    return (
      <InputText
        {...register(Field.Email, {
          required: true,
          validate: validateEmail,
        })}
        uncontrolled
        placeholder={getPlaceholderLabel(Field.Email, translate('fields.email.title'))}
        validation={renderValidation(getErrorMessage(Field.Email))}
        aria={{ 'aria-required': true }}
        {...getInputEvents(Field.Email)}
      />
    )
  }

  function translateTermsAndCondition(key: string) {
    return translate(key, {
      'terms-and-conditions': interpolateLink(TERMS_URL, ClickableElement.TermsAndConditions),
      'pro-terms-and-conditions': interpolateLink(BUSINESS_TERMS_AND_CONDITIONS_URL),
      'pro-terms-of-sale': interpolateLink(BUSINESS_TERMS_OF_SALE_URL),
      'pro-terms-of-use': interpolateLink(BUSINESS_TERMS_URL),
      'privacy-policy': interpolateLink(PRIVACY_POLICY_URL, ClickableElement.PrivacyPolicy),
      impressum: interpolateLink(IMPRESSUM_URL),
    })
  }

  function renderTermsAndConditionsCheckbox() {
    const error = getErrorMessage(Field.AgreeRules)
    const businessTermsAndConditionsKey = isProTermsAndConditionsFSEnabled
      ? 'fields.agree_rules.business.title_pro_terms_and_conditions'
      : 'fields.agree_rules.business.title'

    let tncCheckboxTitleKey = 'fields.agree_rules.title'

    if (shortenTncCheckboxCopyAbTest?.variant === 'on') {
      tncCheckboxTitleKey = 'fields.agree_rules.test.title'
    } else if (tncCheckboxWithoutAgeRestrictionAbTest?.variant === 'on') {
      tncCheckboxTitleKey = 'fields.agree_rules.test.title_without_age_restriction'
    }

    const checkboxLabel = isBusinessAuth
      ? translateTermsAndCondition(businessTermsAndConditionsKey)
      : translateTermsAndCondition(tncCheckboxTitleKey)

    return (
      <>
        <Controller
          defaultValue={false}
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              text={getCheckboxLabel(Field.AgreeRules, checkboxLabel)}
              checked={value}
              onChange={handleCheckboxClick(name, onChange)}
              testId="terms-and-conditions-checkbox"
            />
          )}
          rules={{
            required: true,
          }}
          name={Field.AgreeRules}
          control={control}
        />
        {error && <Spacer size={Spacer.Size.Small} />}
        {renderValidation(error)}
      </>
    )
  }

  function renderFields() {
    return (
      <>
        {renderRealNameField()}
        <InputText
          {...register(Field.Login, {
            required: true,
            minLength: 3,
            maxLength: 20,
            validate: validateLogin,
          })}
          uncontrolled
          placeholder={getPlaceholderLabel(Field.Login, translate('fields.login.title'))}
          validation={
            renderValidation(getErrorMessage(Field.Login)) || translate('fields.login.hint')
          }
          aria={{ 'aria-required': true }}
          {...getInputEvents(Field.Login)}
        />
        {renderEmailField()}
        {isPasswordRequired && (
          <PasswordField
            {...register(Field.Password, {
              required: true,
              validate: value => (value ? validatePassword(value) : undefined),
            })}
            uncontrolled
            validation={
              renderValidation(getErrorMessage(Field.Password)) || translate('fields.password.hint')
            }
            placeholder={getPlaceholderLabel(Field.Password, translate('fields.password.title'))}
            aria={{ 'aria-required': true }}
            {...getInputEvents(Field.Password)}
          />
        )}
        <Cell>
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            <Controller
              defaultValue={newsletterSubscription === NewsletterSubscription.OptOut}
              render={({ field: { value, name, onChange } }) => (
                <Checkbox
                  name={name}
                  text={getCheckboxLabel(
                    Field.SubscribeToNewsletter,
                    translate(newsLetterSubscriptionKey, {
                      // Only used for markets with a custom applied copy (e.g. Hungary)
                      'privacy-policy': interpolateLink(PRIVACY_POLICY_URL),
                    }),
                  )}
                  checked={value}
                  onChange={handleCheckboxClick(name, onChange)}
                  testId="subscribe-newsletter-checkbox"
                />
              )}
              name={Field.SubscribeToNewsletter}
              control={control}
            />
            {renderTermsAndConditionsCheckbox()}
          </SeparatedList>
        </Cell>
      </>
    )
  }

  function renderFooter() {
    return (
      <Cell>
        <Button
          text={translate('actions.submit')}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSubmitButtonClick}
        />
        <Spacer />
      </Cell>
    )
  }

  useEffect(() => {
    if (!isRealNameRequired) return

    async function handleValidateInitialUserData() {
      setIsLoading(true)

      const response = await validateUser({
        user: {
          real_name: realName,
        },
      })

      if ('errors' in response) {
        setErrors(response.errors)
      }

      setIsLoading(false)
    }

    handleValidateInitialUserData()
  }, [realName, setErrors, isRealNameRequired])

  if (isLoading) {
    return <ContentLoader />
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {renderBaseError()}
      {renderFields()}
      {renderFooter()}
    </form>
  )
}

export default RegisterForm
